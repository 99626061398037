<template>
  <div class="list">
    <header>
      <m-page-header title="基础设置" />
    </header>
    <main v-loading="loading">
      <section class="control-bar" v-if="listData.length">
        <el-button size="medium" type="primary" @click="addCustom" :loading="addBtnLoading">
          新增自定义配置
        </el-button>
      </section>
      <section class="content">
        <div v-if="listData.length">
          <SlickList axis="y" v-model:list="listData" @sort-end="dragSort" helperClass="active">
            <SlickItem v-for="(element, index) in listData" :key="element.id" :index="index">
              <div class="box" style="width: 100%">
                <div class="option">
                  <div class="switch">
                    <el-switch
                      v-model="element.isOpen"
                      :loading="element.loading"
                      @change="switchChange($event, element)"
                      @mousedown.stop
                    >
                    </el-switch>
                  </div>
                  <div class="title">{{ element.base_name }}</div>
                  <div class="content">
                    <template v-if="element.icon">
                      <img v-if="element.type == 1 && isUrl(element.icon)" :src="element.icon" />
                      <i v-else :class="['detailmarkettool-iconfont', element.icon]"></i>
                      <div class="content-text">
                        <div class="m-omit" :title="element.content">{{ element.content }}</div>
                      </div>
                    </template>
                    <template v-else>—</template>
                  </div>
                  <div class="sort">
                    <i
                      class="detailmarkettool-iconfont icon-uparrow"
                      @click="sortUp(index)"
                      @mousedown.stop
                    ></i>
                    <i
                      class="detailmarkettool-iconfont icon-uparrow to-down"
                      @click="sortDown(index)"
                      @mousedown.stop
                    ></i>
                  </div>
                  <div class="control">
                    <div>
                      <el-button
                        size="medium"
                        v-if="element.type == 1"
                        @click="delCustom(element)"
                        :loading="element.delLoading"
                        @mousedown.stop
                      >
                        删除
                      </el-button>
                    </div>
                    <router-link :to="`list-config/${element.id}/${element.base_name}`">
                      <el-button size="medium" @mousedown.stop>配置</el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </SlickItem>
          </SlickList>
        </div>
        <el-empty v-else description="请前往操作指南安装插件"></el-empty>
      </section>
    </main>
  </div>
</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort';
import { ref } from 'vue';
import { add, changeState, del, getList, updateOrder } from '../api/list';
import { ElMessage, ElMessageBox } from 'element-plus';

import { isUrl } from '@/tools';
export default {
  components: { SlickList, SlickItem },
  setup() {
    const listData = ref([]);
    const loading = ref(false);
    // 设置渲染界面的数据
    const setListData = async () => {
      const { status, data } = await getList(loading);
      if (status === 0) return;

      listData.value = data.map(item => {
        return { isOpen: item.state === '1', ...item };
      });
    };

    // 开关
    const switchChange = async (isOpen, row) => {
      row.loading = true;
      const { status } = await changeState({ id: row.id });
      if (status) {
        ElMessage({
          type: isOpen ? 'success' : 'warning',
          message: isOpen ? '已开启' : '已关闭' + row.base_name,
        });
      } else {
        row.isOpen = false;
      }
      row.loading = false;
    };

    // 更新排序
    const saveSort = async () => {
      const ids = listData.value
        .map(item => {
          return item.id;
        })
        .join(',');

      const { status } = await updateOrder({ ids }, loading);

      if (status) {
        ElMessage.success('排序成功');
      }
    };

    // 保存排序
    const dragSort = async ({ newIndex, oldIndex }) => {
      if (newIndex === oldIndex) return;
      setTimeout(() => {
        saveSort();
      });
    };

    const addBtnLoading = ref(false);
    // 新增自定义配置
    const addCustom = async () => {
      const { data, status } = await add(addBtnLoading);
      if (status) {
        listData.value.push(data);
        ElMessage.success('添加成功');
      }
    };

    // 删除自定义配置
    const delCustom = element => {
      ElMessageBox.confirm('此操作将永久删除该配置，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          element.delLoading = true;
          const { status } = await del({ id: element.id }, ref(false));
          if (status) {
            ElMessage.success('删除成功');
            listData.value.forEach((item, index) => {
              if (item === element) {
                listData.value.splice(index, 1);
              }
            });
          }
          element.delLoading = false;
        })
        .catch(() => {});
    };

    const sortUp = index => {
      // 若果当前元素处于数组最后一个，则让它排到最后一位
      const nextIndex = index === 0 ? listData.value.length - 1 : index - 1;
      const origin = listData.value.splice(index, 1)[0];
      listData.value.splice(nextIndex, 0, origin);
      saveSort();
    };
    const sortDown = index => {
      // 若果当前元素处于数组最后一个，则让它排到第一位
      const nextIndex = index === listData.value.length - 1 ? 0 : index + 1;
      const origin = listData.value.splice(index, 1)[0];
      listData.value.splice(nextIndex, 0, origin);
      saveSort();
    };
    return {
      listData,
      setListData,
      loading,
      switchChange,
      dragSort,
      addCustom,
      addBtnLoading,
      delCustom,
      sortDown,
      sortUp,
      isUrl,
    };
  },
  mounted() {
    this.setListData();
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont.scss';
main {
  padding: 20px;
  min-height: 200px;
  .control-bar {
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.box {
  padding-bottom: 10px;

  .option {
    padding: 20px 16px;
    background-color: #fff;
    border: solid 1px #e9e9e9;
    border-radius: 4px;
    display: flex;
    cursor: move;
    transition: box-shadow 0.18s linear;

    & > div {
      display: flex;
      align-items: center;
    }
    .switch {
      flex: 1;
    }
    .title {
      flex: 1.5;
    }
    .content {
      display: flex;
      flex: 2.5;
      align-items: center;
      width: 0px;

      .detailmarkettool-iconfont,
      img {
        margin-right: 16px;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
      .content-text {
        width: calc(100% - 44px);
      }
    }
    .sort {
      flex: 1.5;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      .detailmarkettool-iconfont {
        display: inline-block !important;
        font-size: 18px;
        color: #686868;
        text-align: center;
        margin-right: 2px;
        cursor: pointer;
        &.to-down {
          transform: rotate(180deg);
        }

        &:hover {
          color: #000;
        }
      }
    }
    .control {
      flex: 0 0 216px;
      justify-content: space-between;
      .el-button {
        width: 100px;
      }
    }
  }
}
.active {
  .option {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
  }
}

.drag-move {
  transition: transform 0.3s ease-in-out;
}
</style>
