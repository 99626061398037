import request from '@/tools/request';

export const getList = loading => {
  return request({
    url: '/detailmarkettool-config-list.html',
    loading,
  });
};

export const changeState = (params, loading) => {
  return request({
    url: '/detailmarkettool-config-updateState.html',
    loading,
    params,
  });
};

export const updateOrder = (params, loading) => {
  return request({
    url: '/detailmarkettool-config-updateOrderBy.html',
    loading,
    params,
  });
};

export const add = loading => {
  return request({
    url: '/detailmarkettool-config-add.html',
    loading,
  });
};

export const del = (params, loading) => {
  return request({
    url: '/detailmarkettool-config-del.html',
    params,
    loading,
  });
};
